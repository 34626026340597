import React from "react"

import { FaLinkedinIn, FaGithub, FaTwitter, FaInstagram } from "react-icons/fa"

import * as S from "./styles"

export default function Social({ names = true }) {
  return (
    <S.Social>
      <S.SocialItem>
        <a
          href="https://www.linkedin.com/in/brunohenriquesaibert/"
          target="blank"
        >
          <FaLinkedinIn />
          {names && <span>LinkedIn</span>}
        </a>
      </S.SocialItem>
      <S.SocialItem>
        <a href="https://github.com/BrunoSaibert" target="blank">
          <FaGithub />
          {names && <span>Github</span>}
        </a>
      </S.SocialItem>
      <S.SocialItem>
        <a href="https://twitter.com/bh_saibert" target="blank">
          <FaTwitter />
          {names && <span>Twitter</span>}
        </a>
      </S.SocialItem>
      <S.SocialItem>
        <a href="https://www.instagram.com/trebias/" target="blank">
          <FaInstagram />
          {names && <span>Instagram</span>}
        </a>
      </S.SocialItem>
    </S.Social>
  )
}
