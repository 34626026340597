const theme = {
  color: {
    // Primary
    primary_dark: "#0a735e",
    primary: "#11bb99",
    primary_light: "#2eecc6",
    primary_pastel: "#d0fbf2",

    // Grayscale
    light: "#ffffff",

    cloud_light: "#f1f1f4",
    cloud: "#dadbe4",
    cloud_dark: "#b8bbca",

    shadow_light: "#494c62",
    shadow: "#333545",
    shadow_dark: "#1d1f27",

    dark: "#0f1014",
  },
  font: {
    family: ["'Nunito'", "sans-serif"],
    size: {
      xs: ["14px", "12px"],
      sm: ["18px", "16px"],
      md: ["24px", "20px"],
      lg: ["30px", "24px"],
      xl: ["40px", "30px"],
      xxl: ["50px", "40px"],
    },
    weight: {
      extraLight: 200,
      light: 300,
      regular: 400,
      semiBold: 600,
      bold: 700,
      extraBold: 800,
      black: 900,
    },
  },
  grid: {
    breakpoint: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
    maxContainer: {
      sm: 540,
      md: 720,
      lg: 960,
      xl: 1140,
    },
    columns: 12,
    gutter: 15,
  },
  headerSize: ["80px", "60px"],
}

export default theme
