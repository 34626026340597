import styled from "styled-components"

export const Section = styled.section`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  background: ${props => props.theme.color.dark};
`

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${props => props.theme.headerSize[0]};
  transition: 0.4s;

  @media (max-width: ${props => props.theme.grid.breakpoint.md}px) {
    height: ${props => props.theme.headerSize[0]};
  }
`

export const Brand = styled.div`
  flex: 1;

  a {
    color: ${props => props.theme.color.cloud_light};
    font-size: ${props => props.theme.font.size.xl[0]};
    line-height: calc(${props => props.theme.font.size.xl[0]} + 5px);
    font-weight: ${props => props.theme.font.weight.bold};
    text-decoration: none;
    transition: 0.4s;
    letter-spacing: 1px;

    span {
      color: ${props => props.theme.color.primary};
    }

    &:hover {
      color: ${props => props.theme.color.primary_light};

      span {
        color: ${props => props.theme.color.primary_light};
      }
    }
  }

  img {
    width: 100px;
    transition: 0.4s;
  }
`

export const NavList = styled.ul.attrs({ className: "navbar-list" })`
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const NavLink = styled.li`
  list-style: none;
  margin: 0 4px;
  letter-spacing: 1px;

  :not(.btn) {
    a {
      height: 40px;
      display: flex;
      align-items: center;
      padding: 8px 24px;
      color: ${props => props.theme.color.cloud_dark};
      font-size: ${props => props.theme.font.size.sm[1]};
      line-height: calc(${props => props.theme.font.size.sm[1]} + 5px);
      font-weight: ${props => props.theme.font.weight.bold};
      border-radius: 2px;
      transition: all 0.4s;
      text-decoration: none;

      :hover {
        color: ${props => props.theme.color.cloud_light};
        text-decoration: none;
        opacity: 1;
      }
    }
  }
`

export const Toggle = styled.button`
  display: none;

  @media (max-width: ${props => props.theme.grid.breakpoint.md}px) {
    display: flex;
    height: 60px;
    margin: 0;
    background: transparent;
    color: ${props => props.theme.color.shadow_light};
    border: 0;
    width: 50px;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    font-size: ${props => props.theme.font.size.lg[0]};
  }
`

export const Collapse = styled.div`
  display: flex;

  .navbar-menu-responsive-links {
    display: none;

    svg {
      margin-right: 10px;
    }
  }

  svg {
    display: none;
  }

  @media (max-width: ${props => props.theme.grid.breakpoint.md}px) {
    background: ${props => props.theme.color.dark};
    width: 100vw;
    position: absolute;
    left: 0;
    right: 0;
    top: ${props => props.theme.headerSize[0]};
    overflow: hidden;
    height: 0;
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.is-expanded {
      opacity: 1;
      overflow: auto;
      height: calc(100vh - ${props => props.theme.headerSize[0]});
      transition-duration: 0.4s;
      transition-timing-function: ease-in;
    }

    .social {
      padding: 30px 0px;

      svg {
        display: flex;
      }
    }

    ul:not(.social) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      padding: 30px 0;
      margin: 0 auto;
      max-width: ${props => props.theme.grid.maxContainer.sm}px;

      li {
        padding: 0;
        margin: 10px;
        width: calc(50% - 20px);
        height: auto;
        border-radius: 3px;

        .btn {
          width: 100%;
        }

        &:not(.btn) a {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          flex-direction: column;

          background: ${props => props.theme.color.shadow_dark};
          min-height: 160px;
          height: 100%;
          padding: 20px;
          margin: 0;
          color: ${props => props.theme.color.light};

          svg {
            display: block;
            font-size: ${props => props.theme.font.size.xl[0]};
            color: ${props => props.theme.color.primary};
          }
        }
      }
    }
  }

  @media (max-width: ${props => props.theme.grid.breakpoint.sm}px) {
    ul:not(.social) {
      max-width: 100%;
      padding: 0px 5px;
      margin: 0;
    }
  }
`
