import styled from "styled-components"

const getMediaMaxWidth = (media, { theme }) => {
  return `
    @media only screen and (min-width: ${theme.grid.breakpoint[media]}px) {
      max-width: ${theme.grid.maxContainer[media]}px;
    }
  `
}

const getMediaCol = ({ theme, size, sm, md, lg, xl }) => {
  let concat = ""

  if (size) {
    concat += `width: calc(100% * ${((size > theme.grid.columns
      ? theme.grid.columns
      : size) || 1) / theme.grid.columns});`
  }

  if (sm) {
    concat += `@media only screen and (min-width: ${
      theme.grid.breakpoint["sm"]
    }px) {
      width: calc(100% * ${((sm > theme.grid.columns
        ? theme.grid.columns
        : sm) || 1) / theme.grid.columns});
    }`
  }

  if (md) {
    concat += `@media only screen and (min-width: ${
      theme.grid.breakpoint["md"]
    }px) {
      width: calc(100% * ${((md > theme.grid.columns
        ? theme.grid.columns
        : md) || 1) / theme.grid.columns});
    }`
  }

  if (lg) {
    concat += `@media only screen and (min-width: ${
      theme.grid.breakpoint["lg"]
    }px) {
      width: calc(100% * ${((lg > theme.grid.columns
        ? theme.grid.columns
        : lg) || 1) / theme.grid.columns});
    }`
  }

  if (xl) {
    concat += `@media only screen and (min-width: ${
      theme.grid.breakpoint["xl"]
    }px) {
      width: calc(100% * ${((xl > theme.grid.columns
        ? theme.grid.columns
        : xl) || 1) / theme.grid.columns});
    }`
  }

  return concat
}

const getMediaColOffset = ({
  theme,
  offset,
  offsetSm,
  offsetMd,
  offsetLg,
  offsetXl,
  size,
  sm,
  md,
  lg,
  xl,
}) => {
  let concat = ""

  if (offset) {
    concat += `margin-left: calc(100% * ${((offset + size > theme.grid.columns
      ? Math.abs(theme.grid.columns - offset - size)
      : offset) || 1) / theme.grid.columns}) !important;`
  }

  if (offsetSm) {
    concat += `@media only screen and (min-width: ${
      theme.grid.breakpoint["sm"]
    }px) {
      margin-left: calc(100% * ${((offsetSm + sm > theme.grid.columns
        ? Math.abs(theme.grid.columns - offsetSm - sm)
        : offsetSm) || 1) / theme.grid.columns}) !important;
    }`
  }

  if (offsetMd) {
    concat += `@media only screen and (min-width: ${
      theme.grid.breakpoint["md"]
    }px) {
      margin-left: calc(100% * ${((offsetMd + md > theme.grid.columns
        ? Math.abs(theme.grid.columns - offsetMd - md)
        : offsetMd) || 1) / theme.grid.columns}) !important;
    }`
  }

  if (offsetLg) {
    concat += `@media only screen and (min-width: ${
      theme.grid.breakpoint["lg"]
    }px) {
      margin-left: calc(100% * ${((offsetLg + lg > theme.grid.columns
        ? Math.abs(theme.grid.columns - offsetLg - lg)
        : offsetLg) || 1) / theme.grid.columns}) !important;
    }`
  }

  if (offsetXl) {
    concat += `@media only screen and (min-width: ${
      theme.grid.breakpoint["xl"]
    }px) {
      margin-left: calc(100% * ${((offsetXl + xl > theme.grid.columns
        ? Math.abs(theme.grid.columns - offsetXl - xl)
        : offsetXl) || 1) / theme.grid.columns}) !important;
    }`
  }

  return concat
}

export const Container = styled.div.attrs(props => ({
  className: props.fluid ? "container-fluid" : "container",
}))`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-flow: wrap;

  margin: 0 auto;

  width: 100%;
  height: 100%;

  ${props =>
    props.full &&
    `min-height: calc(100vh - ${props.theme.headerSize[0]} - 50px)`};

  ${props =>
    props.fluid
      ? `max-width: 100%;`
      : `max-width: 100%;
    ${getMediaMaxWidth("sm", props)}
    ${getMediaMaxWidth("md", props)}
    ${getMediaMaxWidth("lg", props)}
    ${getMediaMaxWidth("xl", props)}`};
`

export const Row = styled.div.attrs({
  className: "row",
})`
  display: flex;
  align-items: ${props => props.alignItems || ""};
  justify-content: ${props => props.justifyContent || ""};
  flex-direction: ${props => props.flexDirection || "row"};
  flex-flow: wrap;

  width: 100%;
  height: auto;

  ${props =>
    props.noGutters &&
    `
    & > .col {
      padding-right: 0px !important;
      padding-left: 0px !important;
    }
  `}
`

export const Col = styled.div.attrs(props => ({
  className: "col",
}))`
  background: ${props => props.theme.color[props.bgColor] || props.bgColor};

  padding-right: ${props => props.theme.grid.gutter}px;
  padding-left: ${props => props.theme.grid.gutter}px;

  ${props =>
    (props.offset ||
      props.offsetSm ||
      props.offsetMd ||
      props.offsetLg ||
      props.offsetXl) &&
    getMediaColOffset(props)}

  ${props =>
    props.size || props.sm || props.md || props.lg || props.xl
      ? getMediaCol(props)
      : `flex: 1;`}
`
