import React from "react"

import { Container, Row, Col } from "../Grid"

import * as S from "./styles"

export default function Intro({ full = true, title, description, img }) {
  return (
    <S.Intro full={full}>
      <Container>
        <Row alignItems="center">
          <Col size="12" lg="6">
            <S.Title color="light">{title}</S.Title>
            <S.Description color="cloud_light">{description}</S.Description>
          </Col>
          <Col lg="6">
            <S.Img src={img.src} alt={img.alt} fluid />
          </Col>
        </Row>
      </Container>
    </S.Intro>
  )
}
