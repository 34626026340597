import React, { Component } from "react"
import { Link } from "gatsby"

import {
  FaBars,
  FaTimes,
  FaUser,
  FaCode,
  FaPen,
  FaEnvelope,
} from "react-icons/fa"

import { Container, Row, Col } from "../../Grid"
import Social from "../../Social"

import * as S from "./styles"

export default class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      toogleMenu: false,
    }
  }

  componentDidMount() {
    document.addEventListener("scrollStart", this.handleCloseMenu())
  }

  handleCloseMenu = () => {
    this.setState({ toogleMenu: false })
    document.body.style.overflow = "auto"
  }

  handleToogleMenu = () => {
    this.setState(prevState => ({ toogleMenu: !prevState.toogleMenu }))

    const { toogleMenu } = this.state

    if (!toogleMenu) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }

  render() {
    const { toogleMenu } = this.state

    return (
      <S.Section id="top">
        <Container>
          <Row>
            <Col>
              <S.Nav id="navbar">
                <S.Brand>
                  <Link to="/">
                    <span>B</span>S
                  </Link>
                </S.Brand>

                <S.Toggle onClick={this.handleToogleMenu}>
                  {toogleMenu ? <FaTimes /> : <FaBars />}
                </S.Toggle>

                <S.Collapse className={toogleMenu ? "is-expanded" : ""}>
                  <S.NavList>
                    <S.NavLink>
                      <Link to="/sobre">
                        <FaUser />
                        Sobre
                      </Link>
                    </S.NavLink>
                    <S.NavLink>
                      <Link to="/portfolio">
                        <FaPen />
                        Experiência
                      </Link>
                    </S.NavLink>
                    <S.NavLink>
                      <Link to="/projetos">
                        <FaCode />
                        Projetos
                      </Link>
                    </S.NavLink>
                    <S.NavLink>
                      <Link to="/contato">
                        <FaEnvelope />
                        Contato
                      </Link>
                    </S.NavLink>
                  </S.NavList>

                  <Social names={false} />
                </S.Collapse>
              </S.Nav>
            </Col>
          </Row>
        </Container>
      </S.Section>
    )
  }
}
