import styled from "styled-components"

export const Text = styled.p`
  color: ${props => props.theme.color[props.color || "light"]};
${props =>
  props.size &&
  `font-size: ${props.theme.font.size[props.size || "md"][0]};
  line-height: calc(${
    props.theme.font.size[props.size || "md"][0]
  } + ${props.line || 5}px);`}

  font-weight: ${props => props.theme.font.weight[props.weight || "regular"]};
  text-align: ${props => props.align || "left"};

  margin-top: ${props => `${props.mt || "0"}px`};
  margin-bottom: ${props => `${props.mb || "0"}px`};
  margin-right: ${props => `${props.mr || "0"}px`};
  margin-left: ${props => `${props.ml || "0"}px`};
`
