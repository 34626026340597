import React from "react"

import PropTypes from "prop-types"

import { ThemeProvider } from "styled-components"
import theme from "../../styles/theme"

import GlobalStyle from "../../styles/global"

import Header from "./header"
// import Footer from './footer';

import * as S from "./styles"

export default function Layout({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <Header />
        <S.Main>{children}</S.Main>
        {/* <Footer /> */}
      </>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
