import styled from "styled-components"

export const Social = styled.ul.attrs({
  className: "social",
})`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  list-style: none;
  margin: 0 -15px;
`

export const SocialItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    padding: 0 15px;
    color: ${props => props.theme.color.primary};
    font-size: ${props => props.theme.font.size.md[0]};
    line-height: calc(${props => props.theme.font.size.md[0]} + 5px);
    transition: 0.4s;
    text-decoration: none;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin-left: 10px;
      color: ${props => props.theme.color.light};
      transition: 0.4s;

      &:hover {
        color: ${props => props.theme.color.primary_light};
      }
    }

    svg {
      display: block;
    }

    &:hover {
      color: ${props => props.theme.color.primary_light};
    }
  }
`
