import styled from "styled-components"

import bg from "../../images/bg.jpg"

export const Intro = styled.section`
  padding: calc(${props => props.theme.headerSize[0]} / 2) 0;
  min-height: ${props =>
    props.full ? `calc(100vh - ${props.theme.headerSize[0]} - 20px)` : "500px"};
  height: ${props => (props.full ? `100%` : "500px")};

  display: flex;
  align-items: center;

  @media (max-width: ${props => props.theme.grid.breakpoint.md}px) {
    min-height: 100%;
    height: 100%;
  }
`

export const Title = styled.h1`
  color: ${props => props.theme.color[props.color]};
  margin-bottom: 20px;
  letter-spacing: 1px;
`

export const Description = styled.h2`
  color: ${props => props.theme.color[props.color]};
  font-weight: ${props => props.theme.font.weight.regular};
  margin-bottom: 20px;
  letter-spacing: 1px;
`

export const Img = styled.img`
  ${props => props.fluid && `max-width: 100%; display: block;`}
  ${props => props.maxHeight && `max-height: 350px;`}
  margin: 0 auto;
`
